<template>
  <v-container>
    <v-row>
      <v-col cols="12"
        md="11">
        <h1>Application Setting</h1>
      </v-col>
    </v-row>
    <v-card 
      class="mt-5"
      max-width="500">
      <v-card-text>
        <v-container>
          <v-text-field
            v-model.number="configuration.maxPushPerJob"
            label="Max Push Per Job"
            outlined
            required />
          <v-text-field
            v-model.trim="configuration.allowPushMessageTime"
            label="Allow Push Message Time"
            outlined
            required />
          <v-text-field
            v-model.number="configuration.pushMessageDelay"
            label="Push Message Delay"
            outlined
            required />
          <v-text-field
            v-model.number="configuration.quotaLimitPerDay"
            label="Quota Limit Per Day"
            outlined
            required />
          <v-text-field
            v-model.number="configuration.maximumConcurrentJobs"
            label="Maximum Concurrent Jobs"
            outlined
            required />
          <v-text-field
            v-model.number="configuration.maximumGeoFenceJobs"
            label="Maximum Geo-Fence Jobs"
            outlined
            required />
          <v-text-field
            v-model.number="configuration.purgeDataDay"
            label="Purge Data"
            outlined
            required />
          <v-radio-group
            v-model="configuration.defaultFcmApi"
            row>
            <v-radio
              label="Legacy API"
              value="legacy"></v-radio>
            <v-radio
              label="Modern API"
              value="msg-modern"></v-radio>
          </v-radio-group>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn 
          color="primary"
          outlined
          @click="updateConfig"> Save </v-btn>
        <!-- <btn-job :job-info.sync="jobData" /> -->
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ApplicationSetting',
  data () {
    return {
      configuration: {
        maxPushPerJob: 0,
        allowPushMessageTime: '',
        pushMessageDelay: 0,
        quotaLimitPerDay: 0,
        maximumConcurrentJobs: 0,
        maximumGeoFenceJobs: 0,
        defaultFcmApi: '',
        purgeDataDay: 0
      }
    }
  },

  async created() {
    await this.$store.dispatch('appConfig/GetAppicationSetting');

    this.configuration.maxPushPerJob = this.dataSetting.configuration.maxPushPerJob;
    this.configuration.allowPushMessageTime = this.dataSetting.configuration.allowPushMessageTime;
    this.configuration.pushMessageDelay = this.dataSetting.configuration.pushMessageDelay;
    this.configuration.quotaLimitPerDay = this.dataSetting.configuration.quotaLimitPerDay;
    this.configuration.maximumConcurrentJobs = this.dataSetting.configuration.maximumConcurrentJobs;
    this.configuration.maximumGeoFenceJobs = this.dataSetting.configuration.maximumGeoFenceJobs;
    this.configuration.defaultFcmApi = this.dataSetting.configuration.defaultFcmApi;
    this.configuration.purgeDataDay = this.dataSetting.configuration.purgeDataDay;
  },

  computed: {
    ...mapGetters({
      dataSetting: 'appConfig/GET_APP_SETTING'
    })
  },

  methods: {
    async updateConfig() {
      await this.$store.dispatch('appConfig/SetAppicationSetting', this.configuration);
    }
  }
}
</script>

<style>

</style>